<template>
  <div class="iss-detail">
    <a-form
      ref="formRef"
      class="detail-wrapper"
      layout="vertical"
      :model="task"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
    >
      <div class="banner">
        <img :src="task.banner" />
        <a-form-item
          v-if="editInfo"
          name="banner"
          extra="建议上传宽高比为16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片"
        >
          <iss-image-upload
            v-model:value="task.banner"
            accept=".jpe,.jpeg,.jpg,.png"
          />
        </a-form-item>
      </div>
      <div class="info">
        <a-descriptions :column="{ sm: 1, md: 2, lg: 3, xxl: 4 }">
          <template #title>
            <template v-if="!editInfo">
              <a-tag :color="status[0]">{{ status[1] }}</a-tag>
              <span class="title">
                {{ task.campaignName }}
              </span>
            </template>
          </template>
          <template #extra>
            <a-space v-if="editInfo">
              <a-button type="primary" @click="handleSave">保存</a-button>
              <a-button @click="handleCancel">取消</a-button>
            </a-space>
            <a-button
              v-else
              type="primary"
              @click="
                $has(`${$route.params.type}:edit`).then(() => (editInfo = true))
              "
            >
              编辑
            </a-button>
          </template>
          <template v-if="!editInfo">
            <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['CalendarTwoTone']" class="mr-5" />
                  开始日期
                </span>
              </template>
              {{ task.startTime }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['CalendarTwoTone']" class="mr-5" />
                  结束日期
                </span>
              </template>
              {{ task.endTime }}
            </a-descriptions-item>
            <!-- <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['CarryOutTwoTone']" class="mr-5" />
                  时区
                </span>
              </template>
              {{ task.timeZone }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['HomeTwoTone']" class="mr-5" />
                  本地语言
                </span>
              </template>
              {{ task.localLanguage }}
            </a-descriptions-item> -->
            <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['BankTwoTone']" class="mr-5" />
                  国家/省市
                </span>
              </template>
              {{ task.country }}
              {{ task.province ? ` • ${task.province}` : '' }}
              {{ task.city ? ` • ${task.city}` : '' }}
            </a-descriptions-item>
            <a-descriptions-item v-if="task.location">
              <template #label>
                <span>
                  <component :is="icons['EnvironmentTwoTone']" class="mr-5" />
                  地址
                </span>
              </template>
              {{ task.location }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component :is="icons['ContactsTwoTone']" class="mr-5" />
                  人数
                </span>
              </template>
              {{ task.campaignScale }}
            </a-descriptions-item>
            <a-descriptions-item v-if="task.campaignDesc">
              <template #label>
                <span>
                  <component :is="icons['ProfileTwoTone']" class="mr-5" />
                  描述
                </span>
              </template>
              {{ task.campaignDesc }}
            </a-descriptions-item>
          </template>
        </a-descriptions>
        <a-row v-if="editInfo" :gutter="20">
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="名称" name="campaignName">
              <a-input
                v-model:value="task.campaignName"
                autocomplete="off"
                placeholder="请输入名称"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="日期" name="date">
              <a-range-picker
                v-model:value="task.date"
                show-time
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <!-- <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="时区" name="timeZone">
              <a-select
                v-model:value="task.timeZone"
                show-search
                placeholder="请选择时区"
                @change="(v, { item }) => (task.timeZoneCal = item.value)"
              >
                <a-select-option
                  v-for="item in timeZoneList"
                  :key="item.label"
                  :value="item.label"
                  :item="item"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="本地语言" name="localLanguage">
              <a-select
                v-model:value="task.localLanguage"
                placeholder="请选择本地语言"
              >
                <a-select-option
                  v-for="item in languageList"
                  :key="item.id"
                  :value="item.itemNameEn"
                >
                  {{
                    $store.state.account.language === 'zh-CN'
                      ? item.itemNameCn
                      : item.itemNameEn
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="国家/省市" style="margin-bottom: 0">
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-form-item name="country" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.country"
                      show-search
                      disabled
                      placeholder="请选择国家"
                      :filter-option="filterOption"
                      @change="handleChangeByCountry"
                    >
                      <a-select-option
                        v-for="item in countryList"
                        :key="item.id"
                        :value="item.itemNameCn"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item name="province" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.province"
                      allow-clear
                      show-search
                      placeholder="请选择省份"
                      @change="handleChangeByProvince"
                    >
                      <a-select-option
                        v-for="item in provinceList"
                        :key="item.id"
                        :value="item.itemCode"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item name="city" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.city"
                      allow-clear
                      show-search
                      placeholder="请选择城市"
                      @change="handleChangeByCity"
                    >
                      <a-select-option
                        v-for="item in cityList"
                        :key="item.id"
                        :value="item.itemCode"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="地址" name="location">
              <a-input v-model:value="task.location" placeholder="请输入地址" />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="人数" name="campaignScale">
              <a-input-number v-model:value="task.campaignScale" :min="0" />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="16">
            <a-form-item label="描述" name="campaignDesc">
              <a-textarea
                v-model:value="task.campaignDesc"
                showCount
                :maxlength="100"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </a-form>
    <a-tabs>
      <template #tabBarExtraContent>
        <a-space v-if="editApp">
          <a-button type="primary" shape="circle" @click="handleClickByEdit">
            <template #icon>
              <component :is="icons['CheckOutlined']" />
            </template>
          </a-button>
          <a-button shape="circle" @click="handleClickByClose">
            <template #icon>
              <component :is="icons['CloseOutlined']" />
            </template>
          </a-button>
        </a-space>
        <a-button
          v-else
          type="primary"
          shape="circle"
          @click="
            $has(`${$route.params.type}:edittool`).then(() => (editApp = true))
          "
        >
          <template #icon>
            <component :is="icons['ControlOutlined']" />
          </template>
        </a-button>
      </template>
      <a-tab-pane key="tool" tab="工具集">
        <div class="app-list">
          <template v-for="(item, index) of appList" :key="index">
            <div class="app-title">{{ item.key }}</div>
            <div class="app-wrapper">
              <template v-for="app in item.datas" :key="app.appClientId">
                <div
                  v-if="
                    editApp ||
                    selectedApps.includes(`${app.stepName}-${app.appClientId}`)
                  "
                  :class="{
                    'app-item': true,
                    'no-edit': !editApp,
                    'no-check': !editApps.includes(
                      `${app.stepName}-${app.appClientId}`
                    ),
                  }"
                  @click="editApp || handleClickByApp(app)"
                >
                  <span v-if="editApp" class="item-btn">
                    <component
                      v-if="
                        editApps.includes(`${app.stepName}-${app.appClientId}`)
                      "
                      class="close"
                      :is="icons['CloseCircleFilled']"
                      @click="handleClickByEditApp(app)"
                    />
                    <component
                      v-else
                      class="check"
                      :is="icons['CheckCircleFilled']"
                      @click="handleClickByEditApp(app)"
                    />
                  </span>
                  <div class="item-icon">
                    <component class="iconfont" :is="icons[app.icon]" />
                  </div>
                  <div class="item-name">{{ app.appClientName }}</div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import {
  Col,
  DatePicker,
  Descriptions,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  TreeSelect,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import moment from 'moment';
import { dateFormat } from '@/utils';
import timeZoneList from '@/dictionaries/timeZone.json';
import activityApi from '@/api/activity';
import dictionaryApi from '@/api/dictionary';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATag: Tag,
    ASpace: Space,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    ATreeSelect: TreeSelect,
    IssImageUpload,
  },
  data() {
    const required = { required: true, message: '不能为空' };
    return {
      icons,
      timeZoneList,
      languageList: [],
      countryList: [],
      provinceList: [],
      cityList: [],
      task: { date: null },
      status: ['success', '进行中'],
      appList: [],
      selectedApps: [],
      editApps: [],
      editApp: false,
      editInfo: false,
      rules: {
        campaignName: required,
        localLanguage: required,
        timeZone: required,
        date: required,
        country: required,
      },
    };
  },
  created() {
    this.getDictionary();
    this.getTask();
  },
  methods: {
    getTask() {
      const { type, id } = this.$route.params;
      activityApi.getItemById(`${type}:detail`, id).then(data => {
        const { startTime, endTime, templateAppList, tempApp, province } = data;
        Object.assign(this.task, data, {
          date: [moment(startTime), moment(endTime)],
        });
        const now = new Date().getTime();
        const start = new Date(startTime).getTime();
        const end = new Date(endTime).getTime();
        if (now < start) {
          this.status = ['processing', '未开始'];
        } else if (now > end) {
          this.status = ['default', '已结束'];
        }
        const appList = [];
        const selectedApps = [];
        Object.keys(templateAppList).forEach(key => {
          if (key.indexOf('前') > -1 || ['制作'].includes(key)) {
            appList[0] = { key, datas: templateAppList[key] };
          } else if (key.indexOf('中') > -1 || ['引流'].includes(key)) {
            appList[1] = { key, datas: templateAppList[key] };
          } else if (key.indexOf('后') > -1 || ['转化'].includes(key)) {
            appList[2] = { key, datas: templateAppList[key] };
          } else {
            appList.push({ key, datas: templateAppList[key] });
          }
        });
        Object.values(tempApp).forEach(item =>
          item.map(i => selectedApps.push(`${i.stepName}-${i.appClientId}`))
        );
        this.appList = appList;
        this.selectedApps = selectedApps.flat();
        this.editApps = selectedApps.flat();
        this.cityList = this.provinceList.find(
          i => i.itemCode === province
        )?.children;
      });
    },
    getDictionary() {
      const { type } = this.$route.params;
      dictionaryApi
        .getItemList(`${type}:update`, { code: 'LOCAL_LANGUAGE' })
        .then(data => (this.languageList = data));
      dictionaryApi
        .getItemTree(`${type}:update`, { code: 'AREA' })
        .then(data => (this.countryList = data));
      dictionaryApi
        .getItemTree(`${type}:update`, { code: 'STATE' })
        .then(data => (this.provinceList = data));
    },
    dateFormat,
    handleClickByApp(data) {
      const { apps } = this.$store.state.common;
      //['forms', 'minisiteList/:id', 'promote'];
      const { path, params } = this.$route;
      if (apps.find(i => i.appId === data.appClientId)) {
        this.$router.push(`${path}/app${data.entry}`);
      } else {
        this.$router.push(
          `${path}/${data.appClientId}?path=${data.entry.replace(
            /:id/,
            params.eventId
          )}`
        );
      }
    },
    handleClickByClose() {
      this.editApp = false;
      this.editApps = this.selectedApps;
    },
    handleClickByEdit() {
      const { type, campaignId } = this.$route.params;
      const apps = [];
      this.editApps.forEach(item => {
        const arr = item.split('-');
        this.appList
          .find(i => i.key === arr[0])
          .datas.forEach(i => {
            i.appClientId === arr[1] &&
              apps.push({ ...i, isConfig: 'true', campaignId });
          });
      });
      activityApi.updateApp(`${type}:edittool`, apps).then(() => {
        this.$message.success('操作成功');
        this.editApp = false;
        this.getTask();
      });
    },
    handleClickByEditApp(data) {
      const key = `${data.stepName}-${data.appClientId}`;
      const index = this.editApps.findIndex(i => i === key);
      if (index > -1) {
        this.editApps.splice(index, 1);
      } else {
        this.editApps.push(key);
      }
    },
    filterOption(input, { item }) {
      return item.itemNameCn.indexOf(input) >= 0;
    },
    handleChangeByCountry() {
      this.task.province = null;
      this.task.city = null;
    },
    handleChangeByProvince(value, option) {
      option || (this.task.province = '');
      this.cityList = option ? option.item.children : [];
      this.task.city = '';
    },
    handleChangeByCity(value, option) {
      option || (this.task.city = '');
    },
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.editInfo = false;
    },
    handleSave() {
      const { type } = this.$route.params;
      this.$refs.formRef.validate().then(() => {
        const date = this.task.date;
        this.task.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.task.startTimestamp = date[0].toDate().getTime();
        this.task.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');
        this.task.endTimestamp = date[1].toDate().getTime();
        activityApi.update(`${type}:update`, this.task).then(() => {
          this.$message.success('操作成功');
          this.editInfo = false;
          this.getTask();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.iss-detail {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  overflow-x: hidden;
}
.detail-wrapper {
  display: flex;
  .banner {
    margin-right: 15px;
    width: 200px;
    height: 120px;
    img {
      margin-bottom: 10px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .info {
    flex: 1;
    .title {
      font-size: 18px;
      vertical-align: middle;
    }
  }
}
.app-list {
  padding: 10px 20px 0 20px;
  text-align: center;
  .app-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .app-wrapper {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    margin-bottom: 26px;
    .app-item {
      position: relative;
      padding: 15px;
      background: #f1f1f1;
      box-shadow: 0 6px 10px 0 @shadow-color;
      border-radius: 10px;
      &.no-check {
        opacity: 0.6;
      }
      .item-btn {
        position: absolute;
        top: -13px;
        right: -8px;
        font-size: 22px;
        cursor: pointer;
        .check {
          color: @success-color;
        }
        .close {
          color: @error-color;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .item-icon {
        background-color: @white;
        border: 1px solid @border-color-base;
        border-radius: 6px;
        padding: 35px 0;
        margin-bottom: 10px;
        .iconfont {
          font-size: 44px;
          color: @blue-5;
          transition: transform 0.3s ease-in-out;
        }
      }
      &.active {
        border-style: solid;
        box-shadow: 0 4px 10px @blue-2;
        opacity: 1;
      }
    }
    .no-edit {
      cursor: pointer;
      &:hover {
        .iconfont {
          transform: scale(1.4);
        }
        .item-name {
          color: @blue-5;
        }
      }
    }
    &:nth-child(4) {
      .app-item {
        .iconfont {
          color: @green-5;
        }
        &.active {
          box-shadow: 0 4px 10px @green-2;
        }
      }
      .no-edit:hover .item-name {
        color: @green-5;
      }
    }
    &:nth-child(6) {
      .app-item {
        .iconfont {
          color: @purple-5;
        }
        &.active {
          box-shadow: 0 4px 10px @purple-2;
        }
      }
      .no-edit:hover .item-name {
        color: @purple-5;
      }
    }
  }
}
</style>
